@charset "UTF-8";

// scss-lint:disable ElsePlacement

/// Directional-property mixins are shorthands for writing properties like
/// the following.
///
/// @ignore You can also use `false` instead of `null`.
///
/// @argument {list} $values
///   List of directional values.
///
/// @example scss
///   .element {
///     @include border-style(dotted null);
///     @include margin(null 0 10px);
///   }
///
///   // CSS Output
///   .element {
///     border-bottom-style: dotted;
///     border-top-style: dotted;
///     margin-bottom: 10px;
///     margin-left: 0;
///     margin-right: 0;
///   }
///
/// @return {list}
///
/// @access private

@function _collapse-directionals($values) {
  $output: null;

  $a: nth($values, 1);
  $b: if(length($values) < 2, $a, nth($values, 2));
  $c: if(length($values) < 3, $a, nth($values, 3));
  $d: if(length($values) < 2, $a, nth($values, if(length($values) < 4, 2, 4)));

  @if $a == 0 { $a: 0; }
  @if $b == 0 { $b: 0; }
  @if $c == 0 { $c: 0; }
  @if $d == 0 { $d: 0; }

  @if $a == $b and $a == $c and $a == $d { $output: $a;          }
  @else if $a == $c and $b == $d         { $output: $a $b;       }
  @else if $b == $d                      { $output: $a $b $c;    }
  @else                                  { $output: $a $b $c $d; }

  @return $output;
}
