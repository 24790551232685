@charset "UTF-8";

// scss-lint:disable SpaceAroundOperator

/// Output directional properties, for instance `margin`.
///
/// @argument {string} $pre
///   Prefix to use.
///
/// @argument {string} $suf
///   Suffix to use.
///
/// @argument {list} $values
///   List of values.
///
/// @require {function} _collapse-directionals
///
/// @require {function} _contains-falsy
///
/// @access private

@mixin _directional-property(
    $pre,
    $suf,
    $values
  ) {

  $top:    $pre + "-top"    + if($suf, "-#{$suf}", "");
  $bottom: $pre + "-bottom" + if($suf, "-#{$suf}", "");
  $left:   $pre + "-left"   + if($suf, "-#{$suf}", "");
  $right:  $pre + "-right"  + if($suf, "-#{$suf}", "");
  $all:    $pre +             if($suf, "-#{$suf}", "");

  $values: _collapse-directionals($values);

  @if _contains-falsy($values) {
    @if nth($values, 1) { #{$top}: nth($values, 1); }

    @if length($values) == 1 {
      @if nth($values, 1) { #{$right}: nth($values, 1); }
    } @else {
      @if nth($values, 2) { #{$right}: nth($values, 2); }
    }

    @if length($values) == 2 {
      @if nth($values, 1) { #{$bottom}: nth($values, 1); }
      @if nth($values, 2) { #{$left}: nth($values, 2); }
    } @else if length($values) == 3 {
      @if nth($values, 3) { #{$bottom}: nth($values, 3); }
      @if nth($values, 2) { #{$left}: nth($values, 2); }
    } @else if length($values) == 4 {
      @if nth($values, 3) { #{$bottom}: nth($values, 3); }
      @if nth($values, 4) { #{$left}: nth($values, 4); }
    }
  } @else {
    #{$all}: $values;
  }
}
