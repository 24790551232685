// main: ../style.scss

/********************************
*********************************
BASIC
*********************************
*********************************/


/* Vector Maps
========================*/
.vmap {
  width: 100%;
  height: em(400);

  .jqvmap-zoomin, .jqvmap-zoomout {
    @include size( em(15,15) );
    font-size: em(15);
    line-height: em(10,15);
  }
}
