// main: ../style.scss
/********************************
 *********************************
Kanban
 *********************************
 *********************************/

/* Kanban Widget 
========================*/
.kanban-widget {
  overflow-x: auto;
  .category-list {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .category-item {
    padding: 1.5em;
    background: #fff;
    margin-right: 1em;
    width: 100%;
    flex: 1;
    height: calc(100vh - $nav-height);

    &:last-of-type {
      margin-right: 0;
    }
    
    .add-new-task {
      cursor: pointer;
    }
  }

  .category-title {
    margin-top: 0;
    margin-bottom: 1.5em;
  }

  .task-item {
    background: $body-bg;
    margin-bottom: em(20);
    padding: 1em;
    position: relative;
    cursor: pointer;
    transition: all $transition-duration $transition-function;

    &:hover {
      background: darken($body-bg, 5%);
    }
    
    .badge {
      font-size: $font-size-base;
    }
  }

  .task-label {
    @include position(absolute, -2px 10px null null);
    line-height: 1;
  }

  .task-title {
    margin: 0 0 .5em;
  }

  .task-item:hover .task-users figure {
    border-color: darken($body-bg, 5%);
  }
  
  .task-users {
    figure {
      margin-bottom: 0;
      margin-left: -20px;
      border: 5px solid $body-bg;
      border-radius: 100px;
      transition: all $transition-duration $transition-function;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
